import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import PropTypes from "prop-types";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const libraries = ["places"];

const CustomMap = (props) => {
  return (
    <div className="h-screen w-screen md:w-full md:h-full">
      <GoogleMap
        mapTypeControl={false}
        mapContainerStyle={mapContainerStyle}
        zoom={props.zoom || 10}
        center={props.center}
        options={{ mapTypeControl: false, streetViewControl: false }}
        {...props}
      >
        {props.children}
      </GoogleMap>
    </div>
  );
};

CustomMap.propTypes = {
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomMap;
