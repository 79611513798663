import { logoutHandler } from "layouts/LayOut";
import { toast } from "react-toastify";

const baseUrl = "https://dev.finncub.com/route";

export const fetchBranchData = async () => {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/branch-id-name-list`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => result?.branch_json)
    .catch((error) => console.error(error));
};

export const fetchOldClusterData = async (branch_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: parseInt(branch_id),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${baseUrl}/api/old_center_fetch_data_with_excel`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const fetchClusterData = async (branch_id, selectedFo) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: branch_id,
    fo_list: selectedFo?.length > 0 ? selectedFo : "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch("https://dev.finncub.com/route/api/Cluster_data_with_excel", requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.blob();
    })
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const makeAreaData = async (branch_id, selectedFo) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: branch_id,
    fo_list: selectedFo?.length > 0 ? selectedFo : "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${baseUrl}/api/sector-center-with-excel-api`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const fetchAreaWiseData = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const raw = JSON.stringify({
    branch_id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  return await fetch(`${baseUrl}/api/fetch-sector-center-data`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const getBranchList = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/sco-wise-branch-list`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => result?.branch_json)
    .catch((error) => console.error(error));
};

export const getSeoBranchList = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/sco-wise-branch-list`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result?.branch_json;
      } else {
        toast.error("No data found");
      }
    })
    .catch((error) => console.error(error));
};

export const getScoList = async (id) => {
  const raw = JSON.stringify({
    branch_id: id,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };
  return await fetch(`${baseUrl}/api/fetch-sfo-branch-user-list`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result?.branch_json;
      } else {
        toast.error("No sco data found");
      }
    })
    .catch((error) => console.error(error));
};

export const getScoDayWiseList = async (branchId, userId) => {
  const raw = JSON.stringify({
    branch_id: branchId,
    user_id: userId,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };
  return await fetch(`${baseUrl}/api/fetch-sfo-old-data`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result;
      } else {
        toast.error("No day wise data found");
      }
    })
    .catch((error) => console.error(error));
};

export const scoClustering = async (branchId, userId) => {
  const raw = JSON.stringify({
    branch_id: branchId,
    user_id: userId,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };
  return await fetch(`${baseUrl}/api/sfo-dynamic-clustering`, requestOptions)
    .then((result) => {
      if (result.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return result.blob();
    })
    .then((blob) => blob)
    .catch((error) => console.error(error));
};

export const scoDynamicClusterData = async (branchId, userId) => {
  const raw = JSON.stringify({
    branch_id: branchId,
    user_id: userId,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };
  return await fetch(`${baseUrl}/api/fetch-sfo-dynamic-data`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result;
      } else {
        toast.error("No day wise data found");
      }
    })
    .catch((error) => console.error(error));
};

export const getNearestCenterList = async (center_id, branch_id, sco_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${baseUrl}/api/fetch-nearest-center?center_id=${center_id}&branch_id=${branch_id}&sco_id=${sco_id}`,
    requestOptions
  )
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result?.branch_json;
      } else {
        toast.error("No data found");
      }
    })
    .catch((error) => console.error(error));
};

export const fetchCenterUserList = async (center_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/fetch-center-detail?center_id=${center_id}`, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result?.data;
      } else {
        toast.error("No data found");
      }
    })
    .catch((error) => console.error(error));
};

export const fetchNearestCenterList = async (center_id, branch_id, sco_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${baseUrl}/api/fetch-nearest-center?center_id=${center_id}&branch_id=${branch_id}&sco_id=${sco_id}`,
    requestOptions
  )
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.json();
    })
    .then((result) => {
      if (result.code === 200) {
        return result?.nearest_center?.[0];
      } else {
        toast.error("No data found");
      }
    })
    .catch((error) => console.error(error));
};

export const downloadScoData = async (branch_id, day) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${baseUrl}/api/sco-new-center-detail?branch_id=${branch_id}&center_day=${day}`,
    requestOptions
  )
    .then((response) => {
      if (response.status === 401) {
        toast.error("Session expired!");
        logoutHandler();
      }
      return response.blob();
    })
    .then((result) => result)
    .catch((error) => console.error(error));
};
