export const convertDataToGraph = (data) => {
  // Extract keys and values from the input data
  const keys = Object.keys(data);
  const values = Object.values(data);

  // Define the labels and datasets structure for the sales data
  const graphData = {
    labels: keys,
    datasets: {
      label: "Center",
      data: values,
    },
  };

  return graphData;
};

export const chunkArray = (arr, chunkSize) => {
  const chunkedArray = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export const calculateRoutes = async (centerData, branchLatLng) => {
  let route;
  let distance;
  const origin = { lat: branchLatLng?.[0], lng: branchLatLng?.[1] }; // Start point
  const destination = { lat: branchLatLng?.[0], lng: branchLatLng?.[1] }; // End point

  const directionsService = new window.google.maps.DirectionsService();
  const waypointsChunks = chunkArray(centerData, 23);
  const promises = waypointsChunks?.map((chunk, index) => {
    const waypoints = chunk.slice(1, -1).map((coord) => ({
      location: new window.google.maps.LatLng(coord?.center_lat, coord?.center_long),
      stopover: true,
    }));

    return new Promise((resolve, reject) => {
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: window.google.maps.TravelMode.WALKING,
          optimizeWaypoints: true,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            let totalDist = 0;
            const route = result?.routes[0];
            route?.legs?.forEach((leg) => {
              totalDist += leg.distance.value;
            });
            // Convert to kilometers
            distance = totalDist / 1000;
            resolve(result);
          } else {
            reject(`error fetching directions ${result}`);
          }
        }
      );
    });
  });

  Promise.all(promises)
    .then((results) => {
      route = results;
    })
    .catch((error) => {
      console.error(error);
    });
  return { route, distance };
};

export function haversineDistance(lat1, lon1, lat2, lon2) {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;
  const R = 6371; // Earth's radius in kilometers

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
}

export const combineRouteResults = (results) => {
  if (!results || results.length === 0) return null;

  // Combine all legs into a single array
  const combinedLegs = results.flatMap((result) => result.routes[0].legs);
  const combineWayPoint = results.flatMap((result) => result.routes[0].waypoint_order);

  // Combine all overview_polylines into a single encoded polyline
  const combinedOverviewPolyline = results
    .map((result) => result.routes[0].overview_polyline.points)
    .join("");

  // Create a new directions object
  const combinedDirections = {
    ...results[0], // Use the first result as the base
    routes: [
      {
        ...results[0].routes[0], // Use the first route as the base
        legs: combinedLegs, // Replace legs with the combined legs
        waypoint_order: combineWayPoint,
        overview_polyline: {
          points: combinedOverviewPolyline, // Replace overview_polyline with the combined one
        },
      },
    ],
  };

  return combinedDirections;
};
