import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../assets/logo/routeLogo.png";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export const logoutHandler = () => {
  localStorage.clear();
  window.location.replace("/sign-in");
};

const LayOut = ({ children }) => {
  const [modalToggle, setModalToggle] = useState(false);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setModalToggle(false);
    window.location.replace("/login");
  };
  return (
    <div>
      <header className="flex justify-between">
        <img className="w-24 h-10 md:h-14 md:w-36" src={Logo} />

        <div className="flex gap-4 text-sm">
          <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "pending")}>
            Clustering
          </NavLink>

          <NavLink to="/sco" className={({ isActive }) => (isActive ? "active" : "pending")}>
            SCO
          </NavLink>
        </div>

        <button
          className="bg-red-500 rounded-md text-white text-lg px-1 md:px-2"
          onClick={() => setModalToggle(true)}
        >
          Log Out
        </button>
      </header>

      <body className="body">{children}</body>

      <Modal
        open={modalToggle}
        onClose={setModalToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to log out?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, display: "flex", gap: 4, justifyContent: "center" }}
          >
            <button style={{ padding: 2, width: 60 }} onClick={handleLogOut}>
              Yes
            </button>
            <button style={{ padding: 2, width: 60 }} onClick={() => setModalToggle(false)}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

LayOut.propTypes = {
  children: PropTypes.children, // Declare children prop
};

export default LayOut;
