import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { json, useNavigate } from "react-router-dom";
import { useAuthServiceProvider, setToken } from "context/auth";
import videosrc from "assets/Video/earthVideo.mp4";
import { getLogin } from "api/apiConfig";
import { toast } from "react-toastify";

function Login() {
  const [authController, dispatchAuth] = useAuthServiceProvider();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  async function sumitSign() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      username: email,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.finncub.com/route/api/login/", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res?.token) {
          localStorage.setItem("token", res?.token);
          navigate("/");
        } else {
          toast.error("Invalid user name or password!");
        }
      })
      .catch((error) => console.error(error));
  }

  const token = localStorage.getItem("token");

  if (token) {
    navigate("/");
  }

  return (
    <div style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,
        }}
      >
        <source src={videosrc} type="video/mp4" />
      </video>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          className="w-4/5 md:w-2/5"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            // width: "30%",
            boxShadow:
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
            borderRadius: "10px",
            background: "white",
          }}
        >
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="User Name"
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  autofocus
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton
                  // disabled={!email || !password}
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => sumitSign()}
                >
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </div>
      </div>
    </div>
  );
}

export default Login;
