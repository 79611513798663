import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMaterialUIController } from "context";
import Login from "layouts/authentication/sign-in";
import Main from "Main";
import LayOut from "layouts/LayOut";
import SCO from "layouts/sco";
import { useLoadScript } from "@react-google-maps/api";

export default function App() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();

  const { darkMode } = controller;
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [pathname]);

  useEffect(() => {
    const checkMidnight = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if (hours === 0 && minutes === 0) {
        localStorage.clear();
        navigate("/sign-in");
      }
    };
    const intervalId = setInterval(checkMidnight, 60000); // Check every 60 seconds
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries,
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  console.log(process.env, "kkk");

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <ToastContainer />

      <Routes>
        <Route path="/sign-in" element={<Login />} />
        {token && (
          <>
            <Route
              path="/"
              exact
              element={
                <LayOut>
                  <Main />
                </LayOut>
              }
            />
            <Route
              path="/sco"
              exact
              element={
                <LayOut>
                  <SCO />
                </LayOut>
              }
            />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
}
